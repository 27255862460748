(function () {
    const faqItems = document.querySelectorAll('.faq-item')
    const popup = document.querySelector('.faq-popup')
    const popupContent = popup.querySelector('.faq-popup__content')
    const popupClose = popup.querySelector('.faq-popup__close')

    faqItems.forEach(item => {
        item.addEventListener('click', function () {
            const id = this.getAttribute('data-id')
            const copy = popup.querySelector(`template[data-id="${id}"]`).innerHTML
            popupContent.innerHTML = copy
            popup.classList.add('opened')
            bodyScrollLock.disableBodyScroll(popup);
        })
    })

    popupClose.addEventListener('click', function () {
        popupContent.innerHTML = ''
    })

    const supportersPopup = document.querySelector('.supporters-popup')
    const supportersOpener = document.querySelector('.view-supporters')

    supportersOpener.addEventListener('click', function (e) {
        e.preventDefault()
        supportersPopup.classList.add('opened')
        bodyScrollLock.disableBodyScroll(supportersPopup);
    })

    const closePopup = document.querySelectorAll('.popup__close')
    closePopup.forEach(close => {
        const popup = close.closest('.popup')
        close.addEventListener('click', function () {
            popup.classList.remove('opened');
            bodyScrollLock.clearAllBodyScrollLocks();
        })
    })
})()