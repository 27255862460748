(function () {
    let initSwiper = false;
    let swiper

    function swiperInit() {

        if (window.innerWidth <= 768) {
            if (!initSwiper) {
                initSwiper = true;
                swiper = new Swiper(".university-slider", {
                    slidesPerView: 1,
                    pagination: {
                        el: '.swiper-pagination',
                    },
                });
            }
        } else if (initSwiper) {
            swiper.destroy();
            initSwiper = false;
        }
    }


    swiperInit();
    window.addEventListener("resize", swiperInit);
})()